import React from "react";
import { Helmet } from 'react-helmet';
import { motion } from "framer-motion";

import "./hero-section.css";
const HeroSection = () => {
  return (
    <>
    {/* SEO Metadata */}
    <Helmet>
        <title>Nora Cake</title>
        <meta name="description" content="Vancouver, Langley, Surrey, Burnaby, home made cake, wedding cake, birthday cake" />
        <meta name="keywords" content="Vancouver, Langley, Surrey, Burnaby, customise cake, home made cake, wedding cake, birthday cake." />
        <meta name="author" content="Nora Cake" />
        <meta property="og:title" content="Top International Songs Playlist" />
        <meta property="og:description" content="International songs, Vancouver Live DJ, Mostly played International songs, International classical music, popular International tracks, Live music at party, live songs at wedding, live song at birthday party." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://djravshan.com/mostly-played-international-songs" />
        <meta property="og:image" content="https://yourwebsite.com/images/international-songs-cover.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Top International Songs Playlist" />
        <meta name="twitter:description" content="Discover a playlist of top International songs." />
        <meta name="twitter:image" content="https://yourwebsite.com/images/international-songs-cover.jpg" />
      </Helmet>
      <div className="ng-tns-c3-1 ng-star-inserted">
        <div
          className="container d-flex align-items-center justify-content-center ng-tns-c3-1"
          style={{ marginTop: "40px" }}
        >
          <div className="row d-flex flex-column-reverse gap-5 gap-xl-0 flex-xl-row ng-tns-c3-1">
            <div className="col-xl-6 gap-3 d-flex flex-column justify-content-center align-items-xl-start ng-tns-c3-1">
              <motion.h1
                whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.8 }}
                className="fw-bold"
                style={{ fontSize: "60px" }}
              >
                Enjoy Homemade delicious cakes!
              </motion.h1>
              
              <motion.p className="fs-5"  whileInView={{ y: [100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.8 }}>
                {" "}
                We make homemade cakes and bakeries with organic ingredients as much as possible.{" "}
              </motion.p>
              {/*
              <motion.button className="herosection-btn"  
                whileInView={{ y: [100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.8 }}
                > Join our discord 
              </motion.button>
              */}
              {/**/}
            </div>
            <motion.div className="col-xl-6 flex justify-content-center ng-tns-c3-1" whileInView={{ x: [100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.8 }}>
              <img src="/assets/noracake-banner.jpeg" alt="" className="herosection-img" />
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
