import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="container footer__div">
          <div className="footer__copyright" />
          <p className="footer__copyright_p">Proudly made in Lower Mainland, BC, Canada | Copyright © 2022~2024 </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
