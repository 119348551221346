import React from "react";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-white mb-3 fixed-top">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img alt="Nora Cake logo" src="assets/nora-cake-logo.png" class="img-responsive careerpage"></img>
            <img alt="Nora Cake logo" src="assets/nora-cake-logo-mobile.png" class="img-responsive careerpage mobile"></img>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
         
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav" style={{ marginLeft: "4%" }}>
                <li className="nav-item">
                  <a
                    className="nav-link text-dark"
                    href="/"
                    style={{
                      marginRight: "40px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Home
                  </a>
                </li>
                { /*}
                <li className="nav-item">
                  <a
                    routerlink="/"
                    fragment="about"
                    className="nav-link text-dark"
                    href="/#about"
                    style={{
                      marginRight: "40px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    About
                  </a>
                </li>
                */ }
                <li className="nav-item">
                  <a
                    routerlink="/"
                    fragment="top-orders"
                    className="nav-link text-dark"
                    href="/#top-orders"
                    style={{
                      marginRight: "40px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Top Cakes
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    routerlink="/"
                    fragment="team"
                    className="nav-link text-dark"
                    href="/#team"
                    style={{
                      marginRight: "40px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Customers saying
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    routerlink="/"
                    fragment="contact"
                    className="nav-link text-dark"
                    href="/#contact"
                    style={{
                      marginRight: "40px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Contact
                  </a>
                </li>
                {/**/}
              </ul>
              {/*<div className="col-sm d-flex align-items-center justify-content-end d-none-nav">
                <button
                  style={{
                    padding: "10px 20px",
                    border: "none",
                    backgroundColor: "rgb(45, 115, 255)",
                    color: "white",
                    borderRadius: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Contact Us{" "}
                </button>
              </div>*/}
            </div>
        </div>
    </nav>
  );
};

export default Navbar;
