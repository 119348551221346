import React from "react";
import {
  HeroSection,
  TopCakes,
  Team,
  Contact
} from '../components/HomeComponents/index'
const Home = () => {
  return (<>
  
  <div>
    <HeroSection/>
    { /*<About/> */ }
    <TopCakes/>
    { /*<Roadmap/>*/ }
    <Team/>
    <Contact/>
  </div>
  </>);
};

export default Home;
