import React from "react";
import './team.css'

const Team = () => {
  return (
    <>
      <div
        className="container-sm px-4 px-xs-5 px-sm-0 d-flex align-items-center justify-content-center home__team"
        id="team"
      >
        <div>
          <div>
            <h2 className="text-center">What customers are saying</h2>
            <p className="fs-5 home__team_p">
              {" "}
              I always enjoy reading all customer feedbacks.{" "}
            </p>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-6 py-5 gap-4 d-flex flex-column align-items-center">
              <img
                alt="image1"
                src="/assets/feedbacks/Pihu.jpeg"
                className="home__team_img"
              />
              <div className="d-flex flex-column align-items-center">
                <h1 className="fs-5 fw-bold">Dipti</h1>
                <h6 className="home__team_p_position">About honey cake</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 py-5 gap-4 d-flex flex-column align-items-center">
              <img
                alt="image2"
                src="/assets/feedbacks/Alina.jpeg"
                className="home__team_img"
              />
              <div className="d-flex flex-column align-items-center">
                <h1 className="fs-5 fw-bold">Nigina</h1>
                <h6 className="home__team_img">About honey cake</h6>
              </div>
            </div>
            
            <div className="col-md-6 col-sm-6 py-5 gap-4 d-flex flex-column align-items-center">
              <img
                  alt="image1"
                  src="/assets/feedbacks/Karen.jpeg"
                  className="home__team_img"
              />
              <div className="d-flex flex-column align-items-center">
                <h1 className="fs-5 fw-bold">Karen</h1>
                <h6 className="home__team_p_position">About honey cake</h6>
              </div>
            </div>
            {/**/}
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
