import React from "react";
import "./contact.css";
// import { BsFacebook, BsInstagram } from "react-icons/bs";
import { AiTwotonePhone, AiOutlineMail, AiTwotoneCar } from "react-icons/ai";
const Contact = () => {
  return (
    <>
      <div className="container" id="contact">
        <h2 className="text-center">Connect with us</h2>
        <div className="d-flex justify-content-center mb-5">
          <ul className="d-flex align-items-center home__contact_ul">
            <li className="home__contact_li">
            <a href="tel:2369993666">
                <AiTwotonePhone />
              </a>
            </li>
            <li className="home__contact_li">
              <a href="mailto:norataxirovna@gmail.com" 
                target="_top" 
                rel="noopener noreferrer">
                <AiOutlineMail />
              </a>
            </li>
            <li className="home__contact_li">
              <a href="https://maps.app.goo.gl/bNzHokJiE52WTssc8" 
                target="_blank" 
                rel="noopener noreferrer">
                <AiTwotoneCar />
              </a>
            </li>
            {
            /*<li className="home__contact_li">
              <a href="https://www.facebook.com/norabakeryca" 
                target="_blank" 
                rel="noopener noreferrer">
                <BsFacebook />
              </a>
            </li>
            <li className="home__contact_li">
              <a href="https://www.instagram.com/nora_bakery_ca" 
                target="_blank" 
                rel="noopener noreferrer">
                <BsInstagram />
              </a>
            </li> */
            }
          </ul>
        </div>
      </div>
    </>
  );
};

export default Contact;
